import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ContactConfirmation = () => {
  const lang = "pl"

  return (
    <Layout lang={lang} translation="/contact-confirmation/">
      <SEO title="Kontakt potwierdzenie" />

      <section className="global-section-contact-confirmation">
        <h1>Dziękujemy za wysłanie wiadomości!</h1>
        <p>Skontaktujemy się z Tobą najszybciej jak to możliwe.</p>
        <Link to="/pl/" className="btn-custom btn-custom--arrow">
          Powrót do strony głównej
        </Link>
      </section>
    </Layout>
  )
}

export default ContactConfirmation
